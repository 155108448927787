import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {},
    "index": {},
    "works": {},
    "works/quarkly": {},
    "works/webask": {},
    "works/stepform": {},
    "works/ukitalt": {},
    "works/ukit": {},
    "works/mini-projects": {},
    "illustrations": {
        "display": "block",
        "grid-template-columns": "repeat(4, 1fr)"
    },
    "draft": {}
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
